import '../css/new-home.scss';
import '../css/home-page.scss';

$(() => {
    const APPLICATION_PATH = window.location.pathname;

    // Condition for displaying correct cards per page
    const IS_PORTAL_PAGE = window.location.pathname.split("/").filter(path => path).length == 1;
   
    // Initialize application type object
    const APPLICATION_TYPE = {
        imgUrlPath: "",
        applicationParam: "",
        cssClass: "",
        labelWording: ""
    };

    $('.ui.app-closed.modal')?.modal('show');
    
    // Path conditions based on window.location.pathname
    if (APPLICATION_PATH.includes("/earlycollege/")) {
        APPLICATION_TYPE.imgUrlPath = "earlycollege/";
        APPLICATION_TYPE.applicationParam = "EarlyAdmit";
        APPLICATION_TYPE.cssClass = "early-college";
        APPLICATION_TYPE.labelWording = `
            APPLY
            <div class="apply-link-wording early-college">
                FOR
                <br>
                EARLY
                <br>
                COLLEGE
            </div>
        `;
    }
    if (APPLICATION_PATH.includes("/highschoolgrad/")) {
        APPLICATION_TYPE.imgUrlPath = "/";
        APPLICATION_TYPE.applicationParam = "highSchoolGrad";
        APPLICATION_TYPE.cssClass = "high-school-grad";
        APPLICATION_TYPE.labelWording = "APPLY";
    }

    // Card data, includes image urls and names. Will populate campus or homepage cards based on pathname
    let cards;
    if (IS_PORTAL_PAGE) {
        cards = {
            'earlycollege': {
                image: "https://www.star.hawaii.edu/cdn/images/shortapp/landing/2/Stillinhighschool.png",
                name: "Enroll in college classes while in Hawai&#699;i High School",
                desc: ""
            },
            'highschoolgrad': {
                image: "https://www.star.hawaii.edu/cdn/images/shortapp/landing/2/Graduatinghighschoolseniors.png",
                name: "Graduating Seniors",
                desc: "Fast track: you are accepted into the UH Community College of your choice"
            }
        }
    }
    else {
        cards = { 
            'HAW': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}HawaiiCC.png`,
                name: "Hawai&#699;i Community College"
            }, 
            'HON': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}HonoluluCC.png`,
                name: "Honolulu Community College"
            }, 
            'KAP': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}Kapiolani.png`,
                name: "Kapi&#699;olani Community College"
            },
            'KAU': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}Kauai.png`,
                name: "Kaua&#699;i Community College"
            }, 
            'LEE': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}Leeward.png`,
                name: "Leeward Community College"
            }, 
            'MAU': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}Maui.png`,
                name: "Maui College"
            }, 
            'WIN': {
                image: `https://www.star.hawaii.edu/cdn/images/shortapp/${APPLICATION_TYPE.imgUrlPath}Windward.png`,
                name: "Windward Community College"
            }
        };
    } 

    /**
     * Loop will create the card html with appropriate titles/availability/event listeners per page
     */
    for (const card in cards) {
        const INFO = cards[card];
        const IS_ACTIVE = $(`#${card}`).attr('is-open') == 1;
        const HAS_ERROR = $(`#${card}`).attr('no-error') == 0;
        const CARD_CONTAINER = $(`#${card}`);
        const { applicationParam, cssClass, labelWording } = APPLICATION_TYPE;

        CARD_CONTAINER.append(`
            <div class="image">
                <img src="${INFO.image}">
            </div>
            <div class="content">
                <div class="description">
                    <div class="card-header">${INFO.name}</div>
                    ${IS_PORTAL_PAGE ? `<div class="card-desc">${INFO.desc}</div>` : ''}
                </div>
                ${!IS_PORTAL_PAGE ? `<div class="campus-apply-button" id="apply-${card}"></div>` : ''}
            </div>
        `);

        if (!HAS_ERROR) {
            if (IS_ACTIVE) {
                if (!IS_PORTAL_PAGE) {
                    $(`#apply-${card}`).append(
                        `<a href="../app/?campus=${card}&ProgramFilter=${applicationParam}" class="apply-link ${cssClass}">${labelWording}</a>`
                    );
                }

                CARD_CONTAINER.on('click', ()=> {
                    if (!IS_PORTAL_PAGE)
                        window.location.href = `../app/?campus=${card}&ProgramFilter=${applicationParam}`;
                    else   
                        window.location.href = `./${card}`;
                });
            }
            else {
                $(`#apply-${card}`).append('<span class="apply-link disabled">CLOSED</span>');
                CARD_CONTAINER.css({cursor: 'default'});
                CARD_CONTAINER.popup({position: 'top center'});
            }
        }
    }
});